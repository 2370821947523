import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import Container from '../Container';
import TitleH1 from '../Title';

import './intro.css';

export default function Homepage() {
  const data = useStaticQuery(graphql`
    query HomeIntroQuery {
      wpPage(slug: { eq: "home" }) {
        ...HomeIntro
      }
    }
  `);
  const { title, content } = data?.wpPage?.homepageLayout?.introduction;
  const isActive = title !== null && content !== null;
  return (
    isActive && (
      <Container>
        <div className="intro" style={wrapperStyle}>
          {title && <TitleH1>{parse(title)}</TitleH1>}
          {content && <div>{parse(content)}</div>}
        </div>
      </Container>
    )
  );
}

const wrapperStyle = {
  borderTop: `1px solid rgba(0,0,0,0.2)`,
};
